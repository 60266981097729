.accordion {
  :global {
    .adm-list-item {
      padding: 0;
    }
    .adm-list-item-content {
      border: none;
      padding: 0;
    }
    .adm-list-item-content-main {
      padding: 0;
    }
    .adm-collapse-panel-header {
      font-size: 18px;
      line-height: 22px;
      font-weight: 600;
      color: var(--gray-9);
      padding: 8px 0;
    }
  }
}
