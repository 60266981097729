.container {
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: var(--containerMaxWidth);
  margin: 0 auto;
  background-color: initial;

  &.gutter-none {
    padding: 0;

    &.tablet {
      padding: 0;
    }
  }

  &.gutter-sm {
    padding: 0 var(--containerPadding);

    &.tablet {
      padding: 0 var(--containerPaddingTablet);
    }
  }
}
