.latest-news {
  height: inherit;

  &-accordion {
    :global {
      .adm-list-body {
        border: none;
      }
      .adm-collapse-panel-header {
        background-color: var(--background-main);
      }
    }
  }

  &-swiper {
    border-radius: 6px;
    :global {
      .adm-swiper-indicator {
        bottom: 16px;
      }
      .adm-page-indicator-dot {
        border: 1px solid var(--border-indicator);
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin: 0 4px;
      }
      .adm-page-indicator-dot-active {
        background-color: var(--orange);
        border-color: var(--orange);
      }
    }
  }

  &-image {
    position: relative;
    min-height: 168px;
  }
}
