.canvas {
  &-container {
    width: 100%;
    display: flex;
    border: 1px solid transparent;
    min-height: 150px;

    &[aria-invalid='true'] {
      border-color: var(--states-error);
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
